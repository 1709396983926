// Import Custom Page CSS
import "../styles/header.css"

import React, { useCallback, useEffect, useState } from "react"

// Import Components
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
// Import Images
import classnames from "classnames"

function Header() {
  const [prevScrollPos, setPrevScrollPos] = useState<number>(0)
  const [visible, setVisible] = useState(true)
  const [isProductActive, setIsProductActive] = useState(false)
  const [isResourcesActive, setIsResourcesActive] = useState(false)
  const [isCompanyActive, setIsCompanyActive] = useState(false)
  const [isMenuActive, setIsMenuActive] = useState(false)

  const handleScroll = () => {
    const currentScrollPos = typeof window !== "undefined" ? window.scrollY : 0
    const visibility =
      currentScrollPos < 100 || prevScrollPos > currentScrollPos

    setPrevScrollPos(currentScrollPos)
    setVisible(visibility)
  }

  const handleShowMenu = useCallback(() => {
    setIsMenuActive(!isMenuActive)
    document.body.classList.add("no-scroll")
  }, [])

  const handleHideMenu = useCallback(() => {
    // the timer allows Gatsby to redirect to the selected page
    setTimeout(() => {
      setIsMenuActive(false)
      document.body.classList.remove("no-scroll")
    }, 300)
  }, [])

  useEffect(() => {
    setPrevScrollPos(typeof window !== "undefined" ? window.scrollY : 0)
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll)

      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [prevScrollPos])

  return (
    <div
      className={classnames("header", {
        "navbar--hidden": !visible,
      })}
    >
      <div
        className={
          isMenuActive ? "fixed-mobile-head active" : "fixed-mobile-head"
        }
      >
        <Link
          to="/"
          onClick={() => {
            document.body.classList.remove("no-scroll")
          }}
        >
          <span className="logo-box">
            <span className="logo">
              <StaticImage
                src="../images/rooam-icon.svg"
                alt="Rooam Logo"
                placeholder="none"
              />
            </span>
          </span>
        </Link>
        <span
          className={
            isMenuActive ? "toast-box no-select active" : "toast-box no-select"
          }
          onClick={handleShowMenu}
          role="button"
          tabIndex={0}
        >
          <span className="toast">
            <span className="line"></span>
            <span className="line bottom"></span>
          </span>
        </span>
      </div>
      <div
        className={isMenuActive ? "head-link-wrap active" : "head-link-wrap"}
      >
        <div className="mobile-padding">
          <ul className="head-menu right float-right">
            <li className="outline-link">
              <a
                href="https://my.rooam.co/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Log In
              </a>
            </li>
            <li className="nav-btn">
              <Link
                to="/request-demo/"
                onClick={() => {
                  document.body.classList.remove("no-scroll")
                }}
              >
                Request Demo
              </Link>
            </li>
          </ul>
          <ul className="head-menu left">
            <li className="link-group">
              <div
                className={isProductActive ? "nav-btn active" : "nav-btn"}
                onMouseEnter={() => setIsProductActive(true)}
                onMouseLeave={() => setIsProductActive(false)}
                role="link"
                tabIndex={0}
              >
                Products
              </div>
              <ul
                className={isProductActive ? "dropdown active" : "dropdown"}
                onMouseEnter={() => setIsProductActive(true)}
                onMouseLeave={() => setIsProductActive(false)}
              >
                <li>
                  <Link to="/" onClick={handleHideMenu}>
                    Overview
                  </Link>
                </li>
                <li>
                  <Link to="/tab/" onClick={handleHideMenu}>
                    Rooam Tab
                  </Link>
                </li>
                <li>
                  <Link to="/pay/" onClick={handleHideMenu}>
                    Rooam Pay
                  </Link>
                </li>
                <li>
                  <Link to="/order/" onClick={handleHideMenu}>
                    Rooam Order
                  </Link>
                </li>
                <li>
                  <Link to="/text-to-pay/" onClick={handleHideMenu}>
                    Text to Pay
                  </Link>
                </li>
              </ul>
            </li>
            <li className="link-group">
              <div
                className={isResourcesActive ? "nav-btn active" : "nav-btn"}
                onMouseEnter={() => setIsResourcesActive(true)}
                onMouseLeave={() => setIsResourcesActive(false)}
                role="link"
                tabIndex={0}
              >
                Resources
              </div>
              <ul
                className={isResourcesActive ? "dropdown active" : "dropdown"}
                onMouseEnter={() => setIsResourcesActive(true)}
                onMouseLeave={() => setIsResourcesActive(false)}
              >
                <li>
                  <Link to="/contact-help/" onClick={handleHideMenu}>
                    Contact Help
                  </Link>
                </li>
                <li>
                  <Link to="/location-support/" onClick={handleHideMenu}>
                    Location Support
                  </Link>
                </li>
              </ul>
            </li>
            <li className="link-group">
              <div
                className={isCompanyActive ? "nav-btn active" : "nav-btn"}
                onMouseEnter={() => setIsCompanyActive(true)}
                onMouseLeave={() => setIsCompanyActive(false)}
                role="link"
                tabIndex={0}
              >
                Company
              </div>
              <ul
                className={isCompanyActive ? "dropdown active" : "dropdown"}
                onMouseEnter={() => setIsCompanyActive(true)}
                onMouseLeave={() => setIsCompanyActive(false)}
              >
                <li>
                  <Link to="/about/" onClick={handleHideMenu}>
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/careers/" onClick={handleHideMenu}>
                    Careers
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us/" onClick={handleHideMenu}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Header
