/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

// Import Custom Page CSS
import "../styles/global.css"

import { graphql, useStaticQuery } from "gatsby"

import AmexBanner from "./amex-banner"
import Footer from "./footer"
// Import Components
import Header from "./header"
import React from "react"

interface LayoutProps {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <AmexBanner />
      <div className="wrapper">
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

export default Layout
