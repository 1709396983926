import AmexIcon from "../images/amex-icon.png"
import ArrowRight from "../images/icons/arrow-right.png"
import React from "react"

function AmexBanner() {
  return (
    <div className="amex-banner">
      <div className="small-col">
        <img src={AmexIcon} alt="American Express Logo" />
      </div>
      <div className="mid-col">American Express has acquired Rooam.</div>
      <div className="small-col">
        <a
          className="arrow-right"
          href="https://www.americanexpress.com/en-us/newsroom/articles/travel-and-dining/to-enhance-dining-platform--american-express-enters-agreement-to.html"
          target="_blank"
        >
          <img src={ArrowRight} alt="Go to press release" />
        </a>
      </div>
    </div>
  )
}

export default AmexBanner
