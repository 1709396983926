// Import Custom Page CSS
import "../styles/footer.css"

import React, { Component, useEffect, useState } from "react"

// Import Images
import Icon from "../images/rooam-icon-flat-grey.svg"
import { Link } from "gatsby"

function Footer() {
  const [isFootProductActive, setIsFootProductActive] = useState(false)
  const [isFootResourcesActive, setIsFootResourcesActive] = useState(false)
  const [isFootCompanyActive, setIsFootCompanyActive] = useState(false)
  const [currentYear, setCurrentYear] = useState<string | undefined>()

  useEffect(() => {
    const year = new Date().getFullYear().toString()

    setCurrentYear(year)
  }, [])

  return (
    <div className="section footer no-bottom">
      <div className="container medium text-center">
        <div className="row clearfix">
          <div className="column one-three break-960-full">
            <div className="padding no-top-bottom">
              <span
                className={
                  isFootProductActive
                    ? "foot-title no-select active"
                    : "foot-title no-select"
                }
                onClick={() => {
                  setIsFootProductActive(!isFootProductActive)
                }}
                onKeyDown={() => {
                  setIsFootProductActive(!isFootProductActive)
                }}
                role="link"
                tabIndex={0}
              >
                Products
                <span className="icon arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-icon"
                    viewBox="0 0 24 24"
                  >
                    <path d="M11,5v11.2l-4.9-4.9c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l6.6,6.6c0.4,0.4,1,0.4,1.4,0l6.6-6.6c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L13,16.2V5c0-0.5-0.4-1-1-1S11,4.5,11,5z" />
                  </svg>
                </span>
              </span>
              <ul
                className={
                  isFootProductActive
                    ? "foot-links no-select active"
                    : "foot-links no-select"
                }
              >
                <li>
                  <Link to="/">Overview</Link>
                </li>
                <li>
                  <Link to="/tab/">Rooam Tab</Link>
                </li>
                <li>
                  <Link to="/pay/">Rooam Pay</Link>
                </li>
                <li>
                  <Link to="/order/">Rooam Order</Link>
                </li>
                <li>
                  <Link to="/text-to-pay/">Text to Pay</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="column one-three break-960-full">
            <div className="padding no-top-bottom">
              <span
                className={
                  isFootResourcesActive
                    ? "foot-title no-select active"
                    : "foot-title no-select"
                }
                onClick={() => {
                  setIsFootResourcesActive(!isFootResourcesActive)
                }}
                onKeyDown={() => {
                  setIsFootResourcesActive(!isFootResourcesActive)
                }}
                role="button"
                tabIndex={0}
              >
                Resources
                <span className="icon arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-icon"
                    viewBox="0 0 24 24"
                  >
                    <path d="M11,5v11.2l-4.9-4.9c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l6.6,6.6c0.4,0.4,1,0.4,1.4,0l6.6-6.6c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L13,16.2V5c0-0.5-0.4-1-1-1S11,4.5,11,5z" />
                  </svg>
                </span>
              </span>
              <ul
                className={
                  isFootResourcesActive
                    ? "foot-links no-select active"
                    : "foot-links no-select"
                }
              >
                <li>
                  <Link to="/contact-help/">Contact Help</Link>
                </li>
                <li>
                  <Link to="/location-support/">Location Support</Link>
                </li>
                <li>
                  <Link to="/request-demo/">Request Demo</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="column one-three break-960-full">
            <div className="padding no-top-bottom">
              <span
                className={
                  isFootCompanyActive
                    ? "foot-title no-select active"
                    : "foot-title no-select"
                }
                onClick={() => {
                  setIsFootCompanyActive(!isFootCompanyActive)
                }}
                onKeyDown={() => {
                  setIsFootCompanyActive(!isFootCompanyActive)
                }}
                role="button"
                tabIndex={0}
              >
                Company
                <span className="icon arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-icon"
                    viewBox="0 0 24 24"
                  >
                    <path d="M11,5v11.2l-4.9-4.9c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l6.6,6.6c0.4,0.4,1,0.4,1.4,0l6.6-6.6c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L13,16.2V5c0-0.5-0.4-1-1-1S11,4.5,11,5z" />
                  </svg>
                </span>
              </span>
              <ul
                className={
                  isFootCompanyActive
                    ? "foot-links no-select active"
                    : "foot-links no-select"
                }
              >
                <li>
                  <Link to="/about/">About</Link>
                </li>
                <li>
                  <Link to="/careers/">Careers</Link>
                </li>
                <li>
                  <Link to="/contact-us/">Contact Us</Link>
                </li>
                <li>
                  <Link to="/legal/">Legal</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container text-center">
        <div className="section foot-bottom">
          <ul className="social">
            <li>
              <a
                href="https://www.facebook.com/rooam/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-icon"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z" />
                  </svg>
                </span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/rooam/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-icon"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                </span>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/rooam"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-icon"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                  </svg>
                </span>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/rooam"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-icon"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                  </svg>
                </span>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/rooam"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-icon"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
                  </svg>
                </span>
              </a>
            </li>
          </ul>
          <span className="logo-icon">
            <img src={Icon} alt="Rooam Icon" />
          </span>
          <p className="tiny no-bottom">
            &#169; {currentYear} Rooam, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
